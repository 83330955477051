
#gallery {
  min-height: 100vh;
  padding-top: 100px;
}
#gallery__nav .btn{
  padding: 8px 16px;

}


 .video_wrapper {
 
  /* padding-top: 56.25%;  */
  margin-bottom: 50px;
  width: 70%;
}
/*
.video_player{
  position: absolute;
  top: 0;
  left: 0;
} */



/* .slider__main {
  padding: 70px 0 0 0;
scroll-behavior: smooth;
width: 52%;
overflow: hidden;
position: relative;
left: 50%;
transform: translate(-50%);
} */


/* .react-parallax {
  width: 100%;
  overflow: visible !important;
  position: initial !important;
  z-index: -100;
}
.react-parallax-background-children {
  position: absolute !important;
 
} */
.parallex__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  margin: 100px 0;
}


/* .parallex__container {
  display: grid;
grid-template-columns: 1fr 1fr;
grid-gap: -180px;
align-items: stretch;
padding: 0 189px;
margin-top: 178px;
width: 100%;
margin-bottom: 100px;
} */

.parallex__image {
  width: 50%;
  height: auto;
  border-radius: 5px;
  margin: 26px 0;
  object-fit: cover;
}

.carousel .slider-wrapper {
  width: 100%;
}
.carousel .thumb.selected, .carousel .thumb:hover {
  border: 3px solid var(--header-color);
  padding: 0;
}
.carousel .thumb {
  width: 100px !important;
  height: 50px;
}
.carousel .thumb .slider__image {
  width: 100% !important;
  height: 50px !important;
  object-fit: initial !important;
}
.carousel {
  text-align: center;
}
.carousel-status {
  display: none;
}
.slider__image {
  width: 80% !important;
  height: 400px !important;
  object-fit: contain;

}
@media screen and (max-width: 940px) {
  #gallery {
    height: auto;
    padding-top: 65px;

  }
  #gallery .slider__main {
    padding: 70px 0 0 0;
    scroll-behavior: smooth;
    width: 100%;
  }

  .react-parallax {
    width: 100%;
    overflow: visible !important;
    position: initial !important;
    z-index: -100;
  }
  .react-parallax-background-children {
    position: absolute !important;
   
  }
  .parallex__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
    margin: 100px 0;
  }
  
  
  .parallex__image {
    width: 90%;
  height: auto;
  border-radius: 5px;
  margin: 26px 0;
  object-fit: cover;
  }

  .carousel .slider-wrapper {
    width: 100%;
  }
  .carousel .thumb.selected, .carousel .thumb:hover {
    border: 3px solid var(--header-color);
    padding: 0;
  }
  .carousel .thumb {
    width: 80px !important;
    height: 50px;
    
  }
  .carousel .thumb .slider__image {
    width: 100% !important;
    height: 50px !important;
    object-fit: initial !important;
  }
  .carousel {
    text-align: center;
  }
  .carousel-status {
    display: none;
  }
  .slider__image {
    width: 90% !important;
    height: 42% !important;
    object-fit: cover;
  }
}
@media screen and (max-width: 720px) {
  #gallery {
    height: auto;
    padding-top: 65px;
    
  }
  #gallery .slider__main {
    padding: 70px 0 0 0;
    scroll-behavior: smooth;
    width: 100%;
  }

  .react-parallax {
    width: 100%;
    overflow: visible !important;
    position: initial !important;
    z-index: -100;
  }
  .react-parallax-background-children {
    position: absolute !important;
   
  }
  .parallex__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
    margin: 100px 0;
  }
  
  
  /* .parallex__image {
    width: 300px;
    height: 150px;
    border-radius: 5px;
    margin: 50px 0;
    object-fit: cover;
  } */
  .parallex__image {
    width: 90%;
    height: auto;
    border-radius: 5px;
    margin: 26px 0;
    object-fit: cover;
  }

  .carousel .slider-wrapper {
    width: 100%;
  }
  .carousel .thumb.selected, .carousel .thumb:hover {
    border: 3px solid var(--header-color);
    padding: 0;
  }
  .carousel .thumb {
    width: 70px !important;
    height: 50px;
  }
  .carousel .thumb .slider__image {
    width: 100% !important;
    height: 50px !important;
    object-fit: initial !important;
  }
  .carousel {
    text-align: center;
  }
  .carousel-status {
    display: none;
  }
  .slider__image {
    width: 90% !important;
    height: 300px !important;
    object-fit: contain;
  }
}
@media screen and (max-width: 414px) {
  #gallery {
    height: auto;
    padding-top: 65px;

  }
  #gallery .slider__main {
    padding: 70px 0 0 0;
    scroll-behavior: smooth;
    width: 100%;
  }

  .react-parallax {
    width: 100%;
    overflow: visible !important;
    position: initial !important;
    z-index: -100;
  }
  .react-parallax-background-children {
    position: absolute !important;
   
  }
  .parallex__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
    margin: 100px 0;
  }
  
  
  .parallex__image {
    width: 90%;
    height: auto;
    border-radius: 5px;
    margin: 50px 0;
    object-fit: cover;
  }

  .carousel .slider-wrapper {
    width: 100%;
  }
  .carousel .thumb.selected, .carousel .thumb:hover {
    border: 3px solid var(--header-color);
    padding: 0;
  }
  .carousel .thumb {
    width: 60px !important;
    height: 50px;
   
  }
  .carousel .thumb .slider__image {
    width: 100% !important;
    height: 50px !important;
    object-fit: initial !important;
  }
  .carousel {
    text-align: center;
  }
  .carousel-status {
    display: none;
  }
  .slider__image {
    width: 90% !important;
    height: 300px !important;
    object-fit: contain;
  }
}
@media screen and (max-width: 375px) {
  #gallery {
    height: auto;
    padding-top: 65px;

  }
  #gallery .slider__main {
    padding: 70px 0 0 0;
    scroll-behavior: smooth;
    width: 100%;
  }

  .react-parallax {
    width: 100%;
    overflow: visible !important;
    position: initial !important;
    z-index: -100;
  }
  .react-parallax-background-children {
    position: absolute !important;
   
  }
  .parallex__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
    margin: 100px 0;
  }
  
  
  .parallex__image {
    width: 90%;
    height: auto;
    border-radius: 5px;
    margin: 50px 0;
    object-fit: cover;
  }

  .carousel .slider-wrapper {
    width: 100%;
  }
  .carousel .thumb.selected, .carousel .thumb:hover {
    border: 3px solid var(--header-color);
    padding: 0;
  }
  .carousel .thumb {
    width: 80px !important;
    height: 50px;
  }
  .carousel .thumb .slider__image {
    width: 100% !important;
    height: 50px !important;
    object-fit: initial !important;
  }
  .carousel {
    text-align: center;
  }
  .carousel-status {
    display: none;
  }
  .slider__image {
    width: 99% !important;
    height: 300px !important;
    object-fit: contain;
  }
}