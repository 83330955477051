#activities {
  height: auto;
  padding-top: 100px;
}
.activities__container {
  width: 100%;
}
.activities__notice {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 20px;
}

.activities__nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  background-color: #ffffff47;
  backdrop-filter: blur(1px);
  z-index: 1;
  transition: all 0.2s ease-in !important;
}
.fix {
  position: sticky;
  top: 100px;
  height: auto !important;
  transition: all 0.2s ease-in !important;
  background-color: var(--header-color);
}
.activities__notice p {
  width: 80ch;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.1rem;
  text-align: center;
  color: var(--primary-color);
  padding: 30px 0;
}
.activities__nav .btn {
  padding: 16px 0;
  font-size: 0.9rem;
  color: var(--buttonColor);
  font-weight: 400;
  text-transform: uppercase;
  background-color: var(--header-color);
  cursor: pointer;
  transition: all 0.1s ease-in;
  max-width: 200px;
  width: 190px;
  margin: 0 20px;
  text-align: center;
  color: #fff;
}
/* .activities__nav .btn {
  padding: 10px 0;
  font-size: 0.8rem;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.2s ease-in;
  max-width: 200px;
  width: 190px;
  margin: 0;
  border-radius: none;
  text-align: center;
  color: #fff;
  background-color: var(--header-color);
  margin: 0 3px;
} */
.btn {
  margin-top: 10px;
}
.activities__nav .active {
  color: var(--primary-color) !important;
  background-color: #fff !important;
  font-weight: 700;
  border: none;
}
#activities .slider__main {
  padding: 20px 0 0 0;
  scroll-behavior: smooth;
  width: 100%;
}

@media screen and (max-width: 940px) and (min-width: 720px) {
  #activities {
    padding-top: 60px;
  }
  .activities__container {
    width: 100%;
  }
  .activities__notice {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .activities__nav {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;

  }
  .fix {
    top: 65px;
    
  }
  .activities__notice p {
    width: 45ch;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.1rem;
    text-align: center;
    color: var(--primary-color);
    padding: 30px 0;
  }
  .activities__nav .btn {
    padding: 10px 0;
    font-size: 0.9rem;
    color: var(--buttonColor);
    font-weight: 400;
    text-transform: uppercase;
    color: #fff;
    background-color: var(--header-color);
    cursor: pointer;
    transition: 0.2s ease-in;
    max-width: 200px;
    width: 190px;
    margin: 0 3px;
  }
  .btn {
    margin-top: 10px;
  }
  .activities__nav .active {
    color: #fff;
    background-color: var(--primary-color);
    font-weight: 700;
  }
}

@media screen and (max-width: 720px) and (min-width: 414px) {
  #activities {
    padding-top: 60px;
  }

  .activities__container {
    width: 100%;
  }
  .activities__notice {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .activities__nav {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
    background-color: #ffffff47;
    backdrop-filter: blur(1px);
  }
  .fix {
    top: 65px;
    
  }
  .activities__notice p {
    width: 45ch;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.1rem;
    text-align: center;
    color: var(--primary-color);
    padding: 30px 0;
  }
  .activities__nav .btn {
    padding: 10px 0;
    font-size: 0.9rem;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s ease-in;
    max-width: 200px;
    width: 190px;
    margin: 0;
    border-radius: none;
    text-align: center;
    color: #fff;
    background-color: var(--header-color);
    margin: 0 3px;
  }

  .btn {
    margin-top: 10px;
  }
  .activities__nav .active {
    color: #fff;
    background-color: var(--primary-color);
    font-weight: 700;
    padding: 10px 0;
    border-radius: 0px !important;
    transition: all 0.7s ease;
  }
}

@media screen and (max-width: 413px) {
  #activities {
    padding-top: 60px;
  }

  .activities__container {
    width: 100%;
  }
  .activities__notice {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .activities__nav {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
    background-color: #ffffff47;
    backdrop-filter: blur(1px);
  }
  .fix {
    top: 65px;
    
  }
  .activities__notice p {
    width: 45ch;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.1rem;
    text-align: center;
    color: var(--primary-color);
    padding: 30px 0;
  }
  .activities__nav .btn {
    padding: 10px 0;
    font-size: .9rem;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s ease-in;
    max-width: 200px;
    width: 190px;
    margin: 0;
    border-radius: none;
    text-align: center;
    color: #fff;
    background-color: var(--header-color);
    margin: 0 3px;
  }

  .btn {
    margin-top: 10px;
  }
  .activities__nav .active {
    color: #fff;
    background-color: var(--primary-color);
    font-weight: 700;
    padding: 10px 0;
    border-radius: 0px !important;
    transition: all 0.7s ease;
  }
}
