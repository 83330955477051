@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
:root {
  --primary-color: #041C34;
  --primary-light: #134d86;
  --header-color: #041C34;
  --BG-color: #000;
  --secondary-color: #ffffff;
  --secondary-dark: #e3d002;
  --overlay: rgba(0, 0, 0, 0.562);
  --buttonColor: #0C2D4E;
  --h-color: #000;
  --p-color: #1b1b1b;
  --navbar-color:  #041c3470;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  /* scroll-behavior: smooth; */

  
}
html{
  height: auto;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  line-height: 1.2;

  scroll-behavior: smooth;
  margin-left: 0 !important;
  padding-left:  0 !important;
  
}
.loading_container{
  display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 100vh;
}
.not__found__container{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10000;
  background-color: #fff;
}
.not__found__container h1{
  color: #000;
  color: var(--h-color);
  font-weight: 700;
  letter-spacing: 0.3rem;
  line-height: 35px;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin: 30px 0;
}
.not__found__container a{
  color: #000;
  color: var(--h-color);
  font-weight: 700;
  letter-spacing: 0.3rem;
  line-height: 35px;
  text-transform: uppercase;
  font-size: .7rem;
  margin: 30px 0;
  text-align: center;
  
}

.control-arrow{
  z-index: -2 !important;
}
.control-dots {
  z-index: -1 !important;
  display: none !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.btn {
  padding: 10px 28px;
  font-size: 1rem;
  color: #0C2D4E;
  color: var(--buttonColor);
  font-weight: 400;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid #041C34;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  transition: 0.2s ease-in;
  width: 190px;
}

.btn:hover {
  background-color: #041C34;
  background-color: var(--primary-color);
  border: 1px solid #041C34;
  border: 1px solid var(--primary-color);
  color: #fff;
}


@media screen and (max-width: 940px){
  .section__hedding {
    font-size: 2rem;
    text-align: center;
    margin: 50px 0;
    padding-left: 0rem;
    letter-spacing: .5rem;
  }
  .sub__hedding {
    color: #000;
    color: var(--h-color);
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: .5rem;
    text-align: center;
  }
}




#bannerBoatAndCabin {
    stroke-dasharray: 526.777587890625;
    stroke-dashoffset: 526.777587890625;
    -webkit-animation: bannerBoatAndCabinAnim 2s linear forwards ;
            animation: bannerBoatAndCabinAnim 2s linear forwards ;
}

@-webkit-keyframes bannerBoatAndCabinAnim {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes bannerBoatAndCabinAnim {
    to {
        stroke-dashoffset: 0;
    }
}

/* GALLERY BANNER */

#galleryBanner path:nth-child(2) {
    stroke-dasharray: 427;
    stroke-dashoffset: 427;
    -webkit-animation: galleryBanneranim 2s linear .1s forwards ;
            animation: galleryBanneranim 2s linear .1s forwards ;
}
#galleryBanner path:nth-child(3) {
    stroke-dasharray: 251;
    stroke-dashoffset: 251;
    -webkit-animation: galleryBanneranim 2s linear .2s forwards ;
            animation: galleryBanneranim 2s linear .2s forwards ;
}
#galleryBanner path:nth-child(4) {
    stroke-dasharray: 430;
    stroke-dashoffset: 430;
    -webkit-animation: galleryBanneranim 2s linear .3s forwards ;
            animation: galleryBanneranim 2s linear .3s forwards ;
}
#galleryBanner path:nth-child(5) {
    stroke-dasharray: 426;
    stroke-dashoffset: 426;
    -webkit-animation: galleryBanneranim 2s linear .4s forwards ;
            animation: galleryBanneranim 2s linear .4s forwards ;
}
#galleryBanner path:nth-child(6) {
    stroke-dasharray: 259;
    stroke-dashoffset: 259;
    -webkit-animation: galleryBanneranim 2s linear .5s forwards ;
            animation: galleryBanneranim 2s linear .5s forwards ;
}
#galleryBanner path:nth-child(7) {
    stroke-dasharray: 333;
    stroke-dashoffset: 333;
    -webkit-animation: galleryBanneranim 2s linear .6s forwards ;
            animation: galleryBanneranim 2s linear .6s forwards ;
}
#galleryBanner path:nth-child(8) {
    stroke-dasharray: 259;
    stroke-dashoffset: 259;
    -webkit-animation: galleryBanneranim 2s linear .7s forwards ;
            animation: galleryBanneranim 2s linear .7s forwards ;
    }

@-webkit-keyframes galleryBanneranim {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes galleryBanneranim {
    to {
        stroke-dashoffset: 0;
    }
}

/* ACTIVITIES ANIMATION */
#bannerActivities path {
    stroke-dasharray: 368;
    stroke-dashoffset: 368;
    -webkit-animation: activitiesBannerAnim 2s linear .7s forwards ;
            animation: activitiesBannerAnim 2s linear .7s forwards ;
    }

@-webkit-keyframes activitiesBannerAnim {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes activitiesBannerAnim {
    to {
        stroke-dashoffset: 0;
    }
}
#activities {
  height: auto;
  padding-top: 100px;
}
.activities__container {
  width: 100%;
}
.activities__notice {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 20px;
}

.activities__nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  background-color: #ffffff47;
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  z-index: 1;
  transition: all 0.2s ease-in !important;
}
.fix {
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  height: auto !important;
  transition: all 0.2s ease-in !important;
  background-color: var(--header-color);
}
.activities__notice p {
  width: 80ch;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.1rem;
  text-align: center;
  color: var(--primary-color);
  padding: 30px 0;
}
.activities__nav .btn {
  padding: 16px 0;
  font-size: 0.9rem;
  color: var(--buttonColor);
  font-weight: 400;
  text-transform: uppercase;
  background-color: var(--header-color);
  cursor: pointer;
  transition: all 0.1s ease-in;
  max-width: 200px;
  width: 190px;
  margin: 0 20px;
  text-align: center;
  color: #fff;
}
/* .activities__nav .btn {
  padding: 10px 0;
  font-size: 0.8rem;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.2s ease-in;
  max-width: 200px;
  width: 190px;
  margin: 0;
  border-radius: none;
  text-align: center;
  color: #fff;
  background-color: var(--header-color);
  margin: 0 3px;
} */
.btn {
  margin-top: 10px;
}
.activities__nav .active {
  color: var(--primary-color) !important;
  background-color: #fff !important;
  font-weight: 700;
  border: none;
}
#activities .slider__main {
  padding: 20px 0 0 0;
  scroll-behavior: smooth;
  width: 100%;
}

@media screen and (max-width: 940px) and (min-width: 720px) {
  #activities {
    padding-top: 60px;
  }
  .activities__container {
    width: 100%;
  }
  .activities__notice {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .activities__nav {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;

  }
  .fix {
    top: 65px;
    
  }
  .activities__notice p {
    width: 45ch;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.1rem;
    text-align: center;
    color: var(--primary-color);
    padding: 30px 0;
  }
  .activities__nav .btn {
    padding: 10px 0;
    font-size: 0.9rem;
    color: var(--buttonColor);
    font-weight: 400;
    text-transform: uppercase;
    color: #fff;
    background-color: var(--header-color);
    cursor: pointer;
    transition: 0.2s ease-in;
    max-width: 200px;
    width: 190px;
    margin: 0 3px;
  }
  .btn {
    margin-top: 10px;
  }
  .activities__nav .active {
    color: #fff;
    background-color: var(--primary-color);
    font-weight: 700;
  }
}

@media screen and (max-width: 720px) and (min-width: 414px) {
  #activities {
    padding-top: 60px;
  }

  .activities__container {
    width: 100%;
  }
  .activities__notice {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .activities__nav {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
    background-color: #ffffff47;
    -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
  }
  .fix {
    top: 65px;
    
  }
  .activities__notice p {
    width: 45ch;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.1rem;
    text-align: center;
    color: var(--primary-color);
    padding: 30px 0;
  }
  .activities__nav .btn {
    padding: 10px 0;
    font-size: 0.9rem;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s ease-in;
    max-width: 200px;
    width: 190px;
    margin: 0;
    border-radius: none;
    text-align: center;
    color: #fff;
    background-color: var(--header-color);
    margin: 0 3px;
  }

  .btn {
    margin-top: 10px;
  }
  .activities__nav .active {
    color: #fff;
    background-color: var(--primary-color);
    font-weight: 700;
    padding: 10px 0;
    border-radius: 0px !important;
    transition: all 0.7s ease;
  }
}

@media screen and (max-width: 413px) {
  #activities {
    padding-top: 60px;
  }

  .activities__container {
    width: 100%;
  }
  .activities__notice {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .activities__nav {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
    background-color: #ffffff47;
    -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
  }
  .fix {
    top: 65px;
    
  }
  .activities__notice p {
    width: 45ch;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.1rem;
    text-align: center;
    color: var(--primary-color);
    padding: 30px 0;
  }
  .activities__nav .btn {
    padding: 10px 0;
    font-size: .9rem;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s ease-in;
    max-width: 200px;
    width: 190px;
    margin: 0;
    border-radius: none;
    text-align: center;
    color: #fff;
    background-color: var(--header-color);
    margin: 0 3px;
  }

  .btn {
    margin-top: 10px;
  }
  .activities__nav .active {
    color: #fff;
    background-color: var(--primary-color);
    font-weight: 700;
    padding: 10px 0;
    border-radius: 0px !important;
    transition: all 0.7s ease;
  }
}


#gallery {
  min-height: 100vh;
  padding-top: 100px;
}
#gallery__nav .btn{
  padding: 8px 16px;

}


 .video_wrapper {
 
  /* padding-top: 56.25%;  */
  margin-bottom: 50px;
  width: 70%;
}
/*
.video_player{
  position: absolute;
  top: 0;
  left: 0;
} */



/* .slider__main {
  padding: 70px 0 0 0;
scroll-behavior: smooth;
width: 52%;
overflow: hidden;
position: relative;
left: 50%;
transform: translate(-50%);
} */


/* .react-parallax {
  width: 100%;
  overflow: visible !important;
  position: initial !important;
  z-index: -100;
}
.react-parallax-background-children {
  position: absolute !important;
 
} */
.parallex__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  margin: 100px 0;
}


/* .parallex__container {
  display: grid;
grid-template-columns: 1fr 1fr;
grid-gap: -180px;
align-items: stretch;
padding: 0 189px;
margin-top: 178px;
width: 100%;
margin-bottom: 100px;
} */

.parallex__image {
  width: 50%;
  height: auto;
  border-radius: 5px;
  margin: 26px 0;
  object-fit: cover;
}

.carousel .slider-wrapper {
  width: 100%;
}
.carousel .thumb.selected, .carousel .thumb:hover {
  border: 3px solid var(--header-color);
  padding: 0;
}
.carousel .thumb {
  width: 100px !important;
  height: 50px;
}
.carousel .thumb .slider__image {
  width: 100% !important;
  height: 50px !important;
  object-fit: initial !important;
}
.carousel {
  text-align: center;
}
.carousel-status {
  display: none;
}
.slider__image {
  width: 80% !important;
  height: 400px !important;
  object-fit: contain;

}
@media screen and (max-width: 940px) {
  #gallery {
    height: auto;
    padding-top: 65px;

  }
  #gallery .slider__main {
    padding: 70px 0 0 0;
    scroll-behavior: smooth;
    width: 100%;
  }

  .react-parallax {
    width: 100%;
    overflow: visible !important;
    position: initial !important;
    z-index: -100;
  }
  .react-parallax-background-children {
    position: absolute !important;
   
  }
  .parallex__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
    margin: 100px 0;
  }
  
  
  .parallex__image {
    width: 90%;
  height: auto;
  border-radius: 5px;
  margin: 26px 0;
  object-fit: cover;
  }

  .carousel .slider-wrapper {
    width: 100%;
  }
  .carousel .thumb.selected, .carousel .thumb:hover {
    border: 3px solid var(--header-color);
    padding: 0;
  }
  .carousel .thumb {
    width: 80px !important;
    height: 50px;
    
  }
  .carousel .thumb .slider__image {
    width: 100% !important;
    height: 50px !important;
    object-fit: initial !important;
  }
  .carousel {
    text-align: center;
  }
  .carousel-status {
    display: none;
  }
  .slider__image {
    width: 90% !important;
    height: 42% !important;
    object-fit: cover;
  }
}
@media screen and (max-width: 720px) {
  #gallery {
    height: auto;
    padding-top: 65px;
    
  }
  #gallery .slider__main {
    padding: 70px 0 0 0;
    scroll-behavior: smooth;
    width: 100%;
  }

  .react-parallax {
    width: 100%;
    overflow: visible !important;
    position: initial !important;
    z-index: -100;
  }
  .react-parallax-background-children {
    position: absolute !important;
   
  }
  .parallex__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
    margin: 100px 0;
  }
  
  
  /* .parallex__image {
    width: 300px;
    height: 150px;
    border-radius: 5px;
    margin: 50px 0;
    object-fit: cover;
  } */
  .parallex__image {
    width: 90%;
    height: auto;
    border-radius: 5px;
    margin: 26px 0;
    object-fit: cover;
  }

  .carousel .slider-wrapper {
    width: 100%;
  }
  .carousel .thumb.selected, .carousel .thumb:hover {
    border: 3px solid var(--header-color);
    padding: 0;
  }
  .carousel .thumb {
    width: 70px !important;
    height: 50px;
  }
  .carousel .thumb .slider__image {
    width: 100% !important;
    height: 50px !important;
    object-fit: initial !important;
  }
  .carousel {
    text-align: center;
  }
  .carousel-status {
    display: none;
  }
  .slider__image {
    width: 90% !important;
    height: 300px !important;
    object-fit: contain;
  }
}
@media screen and (max-width: 414px) {
  #gallery {
    height: auto;
    padding-top: 65px;

  }
  #gallery .slider__main {
    padding: 70px 0 0 0;
    scroll-behavior: smooth;
    width: 100%;
  }

  .react-parallax {
    width: 100%;
    overflow: visible !important;
    position: initial !important;
    z-index: -100;
  }
  .react-parallax-background-children {
    position: absolute !important;
   
  }
  .parallex__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
    margin: 100px 0;
  }
  
  
  .parallex__image {
    width: 90%;
    height: auto;
    border-radius: 5px;
    margin: 50px 0;
    object-fit: cover;
  }

  .carousel .slider-wrapper {
    width: 100%;
  }
  .carousel .thumb.selected, .carousel .thumb:hover {
    border: 3px solid var(--header-color);
    padding: 0;
  }
  .carousel .thumb {
    width: 60px !important;
    height: 50px;
   
  }
  .carousel .thumb .slider__image {
    width: 100% !important;
    height: 50px !important;
    object-fit: initial !important;
  }
  .carousel {
    text-align: center;
  }
  .carousel-status {
    display: none;
  }
  .slider__image {
    width: 90% !important;
    height: 300px !important;
    object-fit: contain;
  }
}
@media screen and (max-width: 375px) {
  #gallery {
    height: auto;
    padding-top: 65px;

  }
  #gallery .slider__main {
    padding: 70px 0 0 0;
    scroll-behavior: smooth;
    width: 100%;
  }

  .react-parallax {
    width: 100%;
    overflow: visible !important;
    position: initial !important;
    z-index: -100;
  }
  .react-parallax-background-children {
    position: absolute !important;
   
  }
  .parallex__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
    margin: 100px 0;
  }
  
  
  .parallex__image {
    width: 90%;
    height: auto;
    border-radius: 5px;
    margin: 50px 0;
    object-fit: cover;
  }

  .carousel .slider-wrapper {
    width: 100%;
  }
  .carousel .thumb.selected, .carousel .thumb:hover {
    border: 3px solid var(--header-color);
    padding: 0;
  }
  .carousel .thumb {
    width: 80px !important;
    height: 50px;
  }
  .carousel .thumb .slider__image {
    width: 100% !important;
    height: 50px !important;
    object-fit: initial !important;
  }
  .carousel {
    text-align: center;
  }
  .carousel-status {
    display: none;
  }
  .slider__image {
    width: 99% !important;
    height: 300px !important;
    object-fit: contain;
  }
}
.list-container {
  position: relative;
  z-index: 1000;
}
.list-container.active .more-button-list {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.list-container.active .more-button-list-item {
  -webkit-animation: fadeInItem 0.6s 0.2s forwards;
  animation: fadeInItem 0.6s 0.2s forwards;
}
.list-container.active .more-button-list-item:nth-child(2) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.list-container.active .more-button-list-item:nth-child(3) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.list-container.active .more-button-list-item:nth-child(4) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.list-container.active .more-button {
  -webkit-animation: onePulse 0.6s forwards linear;
  animation: onePulse 0.6s forwards linear;
}
.list-container.active .menu-icon-wrapper {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.list-container.active .menu-icon-line.first {
  -webkit-transform: rotate(-90deg) translateX(1px);
          transform: rotate(-90deg) translateX(1px);
}
.list-container.active .menu-icon-line.last {
  -webkit-transform: rotate(-90deg) translateX(-1px);
          transform: rotate(-90deg) translateX(-1px);
}

.more-button {
  background-color: #1e1e1e;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: 0.2s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
  z-index: 2;
}
.more-button,
.more-button:focus {
  background-color: transparent;
}
.more-button:focus {
  outline: 0;
}
.more-button-list {
  background-color: #fff;
  border-radius: 8px;
  list-style-type: none;
  width: 140px;
  height: 170px;
  box-shadow: 0px 0px 4px 4px rgba(150, 157, 249, 0.16);
  padding: 0;
  padding: 6px;
  position: absolute;
  right: 24px;
  bottom: 0;
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  transition: all 0.3s ease 0.1s;
}
.more-button-list li {
  opacity: 0;
}
.more-button-list-item {
  display: flex;
  align-items: center;
  color: #1c3991;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: 0.2s ease-in;
  -webkit-transform: translatex(-10px);
          transform: translatex(-10px);
}
.more-button-list-item:hover {
  color: #5c67ff;
}
.more-button-list-item:after {
  content: "";
  position: absolute;
  height: 1px;
  width: calc(100% - 24px);
  left: 12px;
  bottom: 0;
  background-color: rgba(132, 160, 244, 0.1);
}
.more-button-list-item:last-child:after {
  display: none;
}
.more-button-list-item svg {
  width: 18px;
  height: 18px;
}
.more-button-list-item span {
  display: inline-block;
  line-height: 20px;
  font-size: 14px;
  margin-left: 8px;
}

@-webkit-keyframes onePulse {
  0% {
    /* box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3); */
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.3);
  }
  50% {
    /* box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1); */
    box-shadow: 0px 0px 0px 12px rgba(255, 255, 255, 0.1);
  }
  100% {
    /* box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.3); */
    box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.3);
  }
}

@keyframes onePulse {
  0% {
    /* box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3); */
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.3);
  }
  50% {
    /* box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1); */
    box-shadow: 0px 0px 0px 12px rgba(255, 255, 255, 0.1);
  }
  100% {
    /* box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.3); */
    box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.3);
  }
}
@-webkit-keyframes fadeInItem {
  100% {
    -webkit-transform: translatex(0px);
            transform: translatex(0px);
    opacity: 1;
  }
}
@keyframes fadeInItem {
  100% {
    -webkit-transform: translatex(0px);
            transform: translatex(0px);
    opacity: 1;
  }
}
.socials {
  position: fixed;
  bottom: 16px;
  right: 16px;
  display: flex;
  align-items: center;
}

.social-link {
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  margin-right: 12px;
}

.menu-icon-wrapper {
  border-radius: 2px;
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: -webkit-transform 330ms ease-out;
  transition: transform 330ms ease-out;
  transition: transform 330ms ease-out, -webkit-transform 330ms ease-out;
}

.menu-icon-line {
  background-color: #fff;
  border-radius: 2px;
  width: 100%;
  height: 2px;
}
.menu-icon-line.half {
  width: 50%;
}
.menu-icon-line.first {
  transition: -webkit-transform 300ms cubic-bezier(0.52, -0.8, 0.52, 0.52);
  transition: transform 300ms cubic-bezier(0.52, -0.8, 0.52, 0.52);
  transition: transform 300ms cubic-bezier(0.52, -0.8, 0.52, 0.52), -webkit-transform 300ms cubic-bezier(0.52, -0.8, 0.52, 0.52);
  -webkit-transform-origin: right;
          transform-origin: right;
}
.menu-icon-line.last {
  align-self: flex-end;
  transition: -webkit-transform 300ms cubic-bezier(0.52, -0.8, 0.52, 0.52);
  transition: transform 300ms cubic-bezier(0.52, -0.8, 0.52, 0.52);
  transition: transform 300ms cubic-bezier(0.52, -0.8, 0.52, 0.52), -webkit-transform 300ms cubic-bezier(0.52, -0.8, 0.52, 0.52);
  -webkit-transform-origin: left;
          transform-origin: left;
}
/*  */
.header {
  position: fixed;
  height: 100px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  overflow: hidden;
  background-color: var(--header-color);
}

.logo {
  position: relative;
  width: 65px;
  z-index: 1000;
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  margin: auto;
  height: 100%;
  padding: 0 30px;
  margin: 0;
}
.header .nav-menu {
  display: flex;
  flex-direction: row;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.header .nav-menu .nav-item a {
  color: #fff;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  font-weight: 500;
}

/* .header .navbar img {
    width: 250px;
    height: auto;
} */

.header .nav-item {
  font-weight: 500;
}
.nav-item .active {
  padding-bottom: 12px;
  border-bottom: 3px solid #fff;
}

.header .nav-item a:hover {
  transition: all 0.2s ease-in;
  color: #5f5f5f;
}

.hamburger {
  display: none;
}

@media screen and (max-width: 940px) and (min-width: 720px) {
  .header {
    max-width: 100%;
    background-color: var(--header-color);
    height: 65px;
  }

  .header .navbar {
    max-width: 940px;
    padding: 0 30px;
  }
  .logo {
    width: 60px;
  }

  .hamburger {
    display: block;
  }

  .header .nav-menu {
    position: fixed;
    left: -100%;
    top: 0;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    z-index: 999;
    text-align: center;
    padding-top: 153px;
    background-color: var(--header-color);
  }

  .header .nav-item {
    padding: 1.5rem;
    font-weight: 500;
  }
  .nav-menu.active {
    left: 0;
  }
  .header .nav-menu a {
    font-weight: 600 !important;
  }
}

@media screen and (max-width: 720px) and (min-width: 414px) {
  /* and (orientation:landscape) */
  .header {
    height: 65px;
  }
  .more-button {
    width: 40px;
    height: 40px;
  }

  .header .nav-menu {
    position: fixed;
    left: -100%;
    top: 0;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    z-index: 999;
    text-align: center;
    transition: 0.3s;
    padding-top: 153px;
    background-color: var(--header-color);
  }
  .header .nav-item {
    padding: 1.5rem;
    font-weight: 500;
  }
  .nav-menu.active {
    left: 0;
  }
  .hamburger {
    display: block;
  }
  .logo {
    width: 50px;
  }
  .menu-icon-wrapper {
    padding: 2px;
  }
  .header .nav-menu a {
    font-weight: 600 !important;
  }
}

@media screen and (max-width: 413px) {
  /* and (orientation:landscape) */
  .header {
    height: 65px;
  }
  .more-button {
    width: 30px;
    height: 30px;
  }

  .header .nav-menu {
    position: absolute;
    left: -100%;
    top: -100%;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    text-align: center;
    padding-top: 0px;
    justify-content: center;
    padding-bottom: 150px;
    background-color: var(--header-color);
  }
  .header .nav-item {
    padding: 1.5rem;
    font-weight: 500;
  }
  .nav-menu.active {
    position: fixed;
    left: 0;
    top: 0;
  }
  .hamburger {
    display: block;
  }
  .logo {
    width: 50px;
  }
  .menu-icon-wrapper {
    padding: 2px;
  }
  .header .nav-menu a {
    font-weight: 600 !important;
  }
}
@media screen and (max-width: 940px) and (orientation: landscape) {
  .header {
    height: 65px;
  }
  .more-button {
    width: 30px;
    height: 30px;
  }
  .header .nav-menu {
    display: flex;
    flex-direction: inherit;
    justify-content: center;
    align-items: center;
  }
  .nav-menu {
    position: absolute;
    left: -100%;
    top: -100%;
    flex-direction: column;
    width: 100%;
    height: 88vh;
    text-align: center;
    padding-top: 0px;
    justify-content: center;
    padding-bottom: 150px;
    background-color: var(--header-color);
  }
  .header .nav-item {
    padding: 0.5rem;
    font-weight: 500;
  }
  .nav-menu.active {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
  }

  .hamburger {
    display: block;
  }
  .logo {
    width: 50px;
  }
  .menu-icon-wrapper {
    padding: 2px;
  }
  .header .nav-menu a {
    font-weight: 600 !important;
  }
}

