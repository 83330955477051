

#bannerBoatAndCabin {
    stroke-dasharray: 526.777587890625;
    stroke-dashoffset: 526.777587890625;
    animation: bannerBoatAndCabinAnim 2s linear forwards ;
}

@keyframes bannerBoatAndCabinAnim {
    to {
        stroke-dashoffset: 0;
    }
}

/* GALLERY BANNER */

#galleryBanner path:nth-child(2) {
    stroke-dasharray: 427;
    stroke-dashoffset: 427;
    animation: galleryBanneranim 2s linear .1s forwards ;
}
#galleryBanner path:nth-child(3) {
    stroke-dasharray: 251;
    stroke-dashoffset: 251;
    animation: galleryBanneranim 2s linear .2s forwards ;
}
#galleryBanner path:nth-child(4) {
    stroke-dasharray: 430;
    stroke-dashoffset: 430;
    animation: galleryBanneranim 2s linear .3s forwards ;
}
#galleryBanner path:nth-child(5) {
    stroke-dasharray: 426;
    stroke-dashoffset: 426;
    animation: galleryBanneranim 2s linear .4s forwards ;
}
#galleryBanner path:nth-child(6) {
    stroke-dasharray: 259;
    stroke-dashoffset: 259;
    animation: galleryBanneranim 2s linear .5s forwards ;
}
#galleryBanner path:nth-child(7) {
    stroke-dasharray: 333;
    stroke-dashoffset: 333;
    animation: galleryBanneranim 2s linear .6s forwards ;
}
#galleryBanner path:nth-child(8) {
    stroke-dasharray: 259;
    stroke-dashoffset: 259;
    animation: galleryBanneranim 2s linear .7s forwards ;
    }

@keyframes galleryBanneranim {
    to {
        stroke-dashoffset: 0;
    }
}

/* ACTIVITIES ANIMATION */
#bannerActivities path {
    stroke-dasharray: 368;
    stroke-dashoffset: 368;
    animation: activitiesBannerAnim 2s linear .7s forwards ;
    }

@keyframes activitiesBannerAnim {
    to {
        stroke-dashoffset: 0;
    }
}