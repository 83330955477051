:root {
  --primary-color: #041C34;
  --primary-light: #134d86;
  --header-color: #041C34;
  --BG-color: #000;
  --secondary-color: #ffffff;
  --secondary-dark: #e3d002;
  --overlay: rgba(0, 0, 0, 0.562);
  --buttonColor: #0C2D4E;
  --h-color: #000;
  --p-color: #1b1b1b;
  --navbar-color:  #041c3470;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  /* scroll-behavior: smooth; */

  
}
html{
  height: auto;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  line-height: 1.2;

  scroll-behavior: smooth;
  margin-left: 0 !important;
  padding-left:  0 !important;
  
}
.loading_container{
  display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 100vh;
}
.not__found__container{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10000;
  background-color: #fff;
}
.not__found__container h1{
  color: var(--h-color);
  font-weight: 700;
  letter-spacing: 0.3rem;
  line-height: 35px;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin: 30px 0;
}
.not__found__container a{
  color: var(--h-color);
  font-weight: 700;
  letter-spacing: 0.3rem;
  line-height: 35px;
  text-transform: uppercase;
  font-size: .7rem;
  margin: 30px 0;
  text-align: center;
  
}

.control-arrow{
  z-index: -2 !important;
}
.control-dots {
  z-index: -1 !important;
  display: none !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.btn {
  padding: 10px 28px;
  font-size: 1rem;
  color: var(--buttonColor);
  font-weight: 400;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  transition: 0.2s ease-in;
  width: 190px;
}

.btn:hover {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: #fff;
}


@media screen and (max-width: 940px){
  .section__hedding {
    font-size: 2rem;
    text-align: center;
    margin: 50px 0;
    padding-left: 0rem;
    letter-spacing: .5rem;
  }
  .sub__hedding {
    color: var(--h-color);
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: .5rem;
    text-align: center;
  }
}

